import React, { useEffect, Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useParams,
	useLocation,
} from "react-router-dom";
import "./openPresentation.css";
import OpenContract from "./OpenContract";

export const ContractUrl = () => {
	const { contract_id, token } = useParams();

	useEffect(() => {}, [contract_id, token]);
	return <OpenContract contract_id={contract_id} token={token} />;
};
