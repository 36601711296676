import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import { APP_COLOURS, Avatars } from "../../APP_VARS";

const mainImages = {
	general: require("./ab1.png"),
	tetrice: require("./pres_bg@2x.webp"),
	techfocus: require("./TechFocus.png"),
	socialmedia: require("./ab1.png"),
	softwaredevelopment: require("./presentation1.webp"),
	reporting: require("./reporting@1x.webp"),
	community: require("./community@1x.png"),
	website: require("./software4@1x.png"),
	website2: require("./software.png"),
};

export default class OpenCoverPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let { h1, h2, pp, h0, mw, contractData } = this.props;
		const isSmallScreen = this.props.screenWidth < 1200;
		return (
			<View
				style={{
					width: "100%",
					backgroundColor: "#000",
					// minHeight: window.innerHeight * 0.95,
				}}>
				<View
					style={{
						width: "100%",
						backgroundColor: APP_COLOURS.BACK_TRANSPARENT2,
						alignItems: "center",
						justifyContent: "center",
						// overflow: "hidden",
						minHeight: window.innerHeight,
					}}>
					{/* <View
						style={{
							width: isSmallScreen ? 120 : 170,
							// flex: 1,
							marginBottom: 20,
							padding: 10,
							alignSelf: "flex-start",
							position: "absolute",
							top: 0,
							left: 0,
						}}>
						<img
							src={require("../../../assets/images/logo_tetrice.png")}
							alt='img'
							className='responsive'
						/>
					</View> */}
					<View
						style={{
							flex: 1,
							// flexDirection: "row",
							// flexDirection: "column-reverse",
							width: "100%",
							// flexWrap: "wrap",
							alignItems: "center",
							// padding: "5%",
							// paddingHorizontal: "5%",
							justifyContent: "center",
						}}>
						<View
							style={{
								flex: 1,
								// maxWidth: 1300,
								// flexDirection: "row-reverse",
								// flexDirection: "row",
								// flexDirection: "row",
								// flexWrap: "wrap-reverse",
								// flexWrap: "wrap",
								justifyContent: "center",
								maxWidth: 1000,
								width: "100%",
								padding: 20,
								// borderWidth: 1,
								// borderColor: APP_COLOURS.BG5,
							}}>
							{isSmallScreen ? (
								<View
									style={{
										// height: window.innerWidth * 0.7,
										width: window.innerWidth,
										maxWidth: 600,
										top: 40,
										maxHeight: 600,
									}}>
									<img
										src={mainImages["tetrice"]}
										alt='Description'
										className='responsive'
									/>
								</View>
							) : (
								<View
									style={{
										// flex: 1,
										position: "absolute",
										// top: -200,
										right: -600,
										minWidth: 350,
										// width: "50%",

										// alignItems: "flex-end",
										justifyContent: "center",
										alignSelf: "flex-end",
										// backgroundColor: "red",
										height: 1000,
										width: 1000,
										// width: isSmallScreen
										// 	? window.innerWidth
										// 	: (window.innerWidth * 0.4) / 2,
									}}>
									<View
										style={{
											width: "100%",
											maxWidth: 1300,
											width: isSmallScreen
												? window.innerWidth
												: (window.innerWidth * 0.8) / 2,
										}}>
										<img
											src={mainImages["tetrice"]}
											alt='Description'
											className='responsive'
										/>
									</View>
								</View>
							)}
							<View
								style={{
									// width: mw,
									// flex: 1,
									// minHeight: window.innerHeight * 0.5,
									paddingTop: isSmallScreen ? 10 : 50,
									// padding: "5%",
									minWidth: 350,
									// paddingHorizontal: "5%",
									width: isSmallScreen
										? window.innerWidth
										: (window.innerWidth * 0.6) / 2,

									paddingLeft: isSmallScreen ? "5%" : 0,
								}}>
								<View
									style={{
										width: isSmallScreen ? 120 : 170,
										// flex: 1,
										marginBottom: 20,
										padding: 10,
										alignSelf: "flex-start",
										position: "absolute",
										top: 0,
										left: 0,
									}}>
									<img
										src={require("../../../assets/images/logo_tetrice.png")}
										alt='img'
										className='responsive'
									/>
								</View>
								<View
									style={{
										// width: window.innerWidth,
										flexDirection: "row",
										flexWrap: "wrap",
										marginBottom: "10%",
										overflow: "hidden",
										justifyContent: "space-between",
										marginTop: 50,
									}}>
									<View
										style={{
											flex: 1,
											maxWidth: 800,
											// opacity: 0.9,
											// marginTop: 50,
										}}>
										<Fade cascade top>
											<Text
												style={{
													fontSize: h0,
													opacity: 1,
													color: "#FFF",
												}}>
												<Font
													family='Poppins'
													weight={800}>
													{contractData?.title}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: h1,
													// opacity: 0.3,
													color: "#FFF",
												}}>
												<Font
													family='Poppins'
													weight={600}>
													{contractData?.title2}
												</Font>
											</Text>

											{/* <Text
												style={{
													fontSize: h1,
													color: "#FFF",
												}}>
												<Font
													family='Poppins'
													weight={400}>
													{contractData?.subTitle}
												</Font>
											</Text> */}
										</Fade>

										<Text
											style={{
												fontSize: pp,
												opacity: 0.6,
												color: "#FFF",
												maxWidth: 500,
											}}>
											<Font family='Poppins' weight={300}>
												{contractData?.description}
											</Font>
										</Text>
									</View>
								</View>

								<View
									style={{
										flex: 1,
										flexDirection: "row",
										// justifyContent: "space-between",
										flexWrap: "wrap",
										// maxWidth: 1300,
										// width: isSmallScreen
										// 	? window.innerWidth
										// 	: (window.innerWidth * 0.8) / 2,
									}}>
									<View
										style={{
											width: isSmallScreen ? 150 : 200,
											// flex: 1,
											marginBottom: 20,
											marginRight: "10%",
										}}>
										<img
											src={require("../../../assets/images/services.png")}
											alt='img'
											className='responsive'
										/>
									</View>
									<View
										style={{
											width: isSmallScreen ? 150 : 200,
											marginBottom: 20,
										}}>
										<img
											src={require("../../../assets/images/infod.png")}
											alt='img'
											className='responsive'
										/>
									</View>
								</View>
							</View>
						</View>
					</View>

					{/* <View
						style={{
							flex: 1,
							flexDirection: "row",
							flexWrap: "wrap",
							alignItems: "center",
							// justifyContent: "space-between",
							// marginBottom: "10%",
							width: "100%",
							// paddingHorizontal: "5%",
							// maxWidth: 1200,
							paddingHorizontal: "5%",
							justifyContent: "center",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								// justifyContent: "space-between",
								flexWrap: "wrap",
								// maxWidth: 1300,
							}}>
							<View
								style={{
									width: 200,
									// flex: 1,
									marginBottom: 20,
									marginRight: "10%",
								}}>
								<img
									src={require("../../../assets/images/services.png")}
									alt='img'
									className='responsive'
								/>
							</View>
							<View
								style={{
									width: 200,
									marginBottom: 20,
								}}>
								<img
									src={require("../../../assets/images/infod.png")}
									alt='img'
									className='responsive'
								/>
							</View>
						</View>
					</View> */}

					<View
						style={{
							// flex: 1,
							width: "100%",
							marginTop: 20,
							backgroundColor: APP_COLOURS.BG,
							justifyContent: "center",
							alignItems: "center",
							paddingVertical: 10,
							paddingHorizontal: "5%",
							display: "none",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								// alignItems: "center",
								flexWrap: "wrap",
								width: "100%",
								// paddingHorizontal: "5%",
								paddingVertical: "2%",
								// justifyContent: "center",
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									flexWrap: "wrap",
									// maxWidth: 1300,
									// alignItems: "flex-end",
									paddingHorizontal: "5%",
									// backgroundColor: "red",
									width: isSmallScreen
										? window.innerWidth
										: (window.innerWidth * 0.8) / 2,
								}}>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										alignItems: "center",
										minWidth: 200,
										paddingVertical: "1%",
									}}>
									<View
										style={{
											width: 36,
											height: 36,
											borderRadius: 20,
											resizeMode: "contain",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "#FFF",
											borderWidth: 2,
											borderColor: "#FFF",
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.44,
											shadowRadius: 10.32,
											elevation: 16,
											marginRight: 10,
										}}>
										{typeof this.props.createdBy !==
										"undefined" ? (
											<Image
												source={
													Avatars[
														parseInt(
															this.props.createdBy
																?.avatar,
														)
													].pp
												}
												style={{
													width: 35,
													height: 35,
													borderRadius: 20,
													resizeMode: "contain",
												}}
											/>
										) : null}
									</View>
									<View>
										<Text
											style={{
												fontSize: 10,
												color: "#FFF",
												top: 6,
											}}>
											<Font family='Poppins' weight={400}>
												Created by:
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 16,
												color: "#FFF",
												marginBottom: 5,
											}}>
											<Font family='Poppins' weight={600}>
												{typeof contractData?.createdBy !==
												"undefined"
													? contractData?.createdBy
															.fullName
													: "..."}
											</Font>
										</Text>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										alignItems: "center",
										width: 200,
										paddingVertical: "1%",
										justifyContent: "space-evenly",
									}}>
									{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}

									<View>
										<Text
											style={{
												fontSize: 10,
												color: "#FFF",
												top: 6,
												opacity: 0.7,
											}}>
											<Font family='Poppins' weight={400}>
												Valid until:
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 16,
												color: "#FFF",
												marginBottom: 5,
											}}>
											<Font family='Poppins' weight={300}>
												{moment(
													contractData?.end_date,
												).format("DD/MM/YYYY")}
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 11,
												color: "#FFF",
												marginBottom: 5,
												opacity: 0.7,
											}}>
											<Font family='Poppins' weight={300}>
												Expires:{" "}
												{moment(
													contractData?.end_date,
												).fromNow()}
											</Font>
										</Text>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										alignItems: "center",
										minWidth: 200,
										paddingVertical: "1%",
									}}>
									{/* <View
									style={{
										width: 40,
									}}>
									<img
										src={require("../assets/services.png")}
										alt='img'
										className='responsive'
									/>
								</View> */}
									{/* <View
										style={{
											display:
												window.innerWidth < 1200
													? "none"
													: "flex",
										}}>
										<Text
											style={{
												fontSize: 10,
												color: "#FFF",
												top: 6,
												opacity: 0.7,
											}}>
											<Font family='Poppins' weight={400}>
												Document number:
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 16,
												color: "#FFF",
												marginBottom: 5,
											}}>
											<Font family='Poppins' weight={300}>
												{contractData?._id}
											</Font>
										</Text>
									</View> */}
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}
}
