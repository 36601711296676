import React, { useState, useEffect } from "react";
import "./openPresentation.css";

function getLargeHtmlData(geturl, next) {
	// POST this data from the parent list or create a new presentation
	let url =
		"https://workflow.tetrice.co.za/webhook/49af9ff2-5530-45b9-b4d8-fb9631c555fb";
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			url: geturl,
		}),
	};
	fetch(url, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			return response.json();
		})
		.then((html) => {
			return next(html.data);
		})
		.catch((error) => {
			console.log(error);
			next(null); // Ensure the callback is called with null to stop loading in case of error
		});
}

const OpenPresentationData = ({
	fontSizes,
	pageData,
	reloadData,
	lockContent,
}) => {
	const [pageTitle, setpageTitle] = useState("");
	const [editorHtml, setEditorHtml] = useState("");
	const [loading, setLoading] = useState(true); // Add loading state
	const [saveHtml, setSaveHtml] = useState(false);

	useEffect(() => {
		setSaveHtml(lockContent);
		if (
			typeof pageData?.htmlData?.htmlFileUrl !== "undefined" &&
			pageData?.htmlData?.htmlFileUrl !== ""
		) {
			getLargeHtmlData(pageData?.htmlData?.htmlFileUrl, (d) => {
				setEditorHtml(d);
				setLoading(false); // Set loading to false once data is fetched
			});
		} else {
			setLoading(false); // Handle case when there's no URL to fetch
		}
	}, [pageData, lockContent]);

	const handleChange = (html) => {
		setEditorHtml(html);
	};

	const handleSave = async () => {
		setSaveHtml(true);
		reloadData(editorHtml);
	};

	return (
		<>
			{loading ? ( // Show loading indicator while loading
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50vh",
						width: "100%",
					}}>
					{/* <div style={{ width: 100, marginBottom: 10 }}>
						<p style={{ color: "#FFF" }}>Loading...</p>
					</div> */}
					<div class='loader'>
						<div class='shimmer'></div>
						<div class='shimmer'></div>
						<div class='shimmer'></div>
						{/* <div class='shimmer'></div> */}
					</div>
				</div>
			) : (
				<div
					className='ql-overflow'
					style={fontSizes}
					dangerouslySetInnerHTML={{ __html: editorHtml }}
				/>
			)}
		</>
	);
};

export default OpenPresentationData;
